import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, of } from 'rxjs';
import { RecommendationsDataService } from 'src/app/services/recommendations.data.service/recommendations.data.service';
import { listAnimation, animateAnimation } from 'src/shared/animations/staggered-list/staggered-list.animation';
import { prodexternal } from 'src/shared/services/client/client';
import { ErrorComponent } from '../design/error/error.component';
import { AsyncPipe, CurrencyPipe, NgFor, NgIf } from '@angular/common';
import { IsClientErrorMessagePipe } from 'src/shared/services/client/client.error.pipe';
import { LoaderComponent } from '../design/loader copy/loader.component';

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  animations: [listAnimation, animateAnimation],
  standalone: true,
  imports: [ErrorComponent, NgIf, AsyncPipe, IsClientErrorMessagePipe, NgFor, LoaderComponent, CurrencyPipe]
})

export class RecommendationsComponent implements OnInit {

  constructor(private recommendationsDataService: RecommendationsDataService, private router: Router) { }
  @Input() product_doc_ids: string[] = [];
  @Input() venom_category_doc_ids: string[] = [];
  @Input() venom_code_doc_ids: string[] = [];
  @Input() venom_group_doc_ids: string[] = [];
  @Input() source: string = "";
  @Input() source_id: string = "";

  ngOnInit(): void {
    this.load();
  }

  recommendations$!: Observable<prodexternal.ListRecommendationsResponse>;

  dashboard() {
    this.router.navigate(['/dashboard']);
  }

  load() {
    this.recommendations$ = this.recommendationsDataService.ListRecommendations(
    {
      ProductDocID: this.product_doc_ids ?? [],
      VenomCategoryDocID: this.venom_category_doc_ids ?? [],
      VenomCodeDocID: this.venom_code_doc_ids ?? [],
      VenomGroupDocID: this.venom_group_doc_ids ?? [],
      Source: this.source ?? "",
      SourceID: this.source_id ?? ""
    }).pipe(
      catchError((err) => {
        return of(err);
      })
    );
  }

}
