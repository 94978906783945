import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl, ValidationErrors } from "@angular/forms";
import { LabelComponent } from "../forms/label/label.component";
import { ValidationComponent } from "../forms/validation/validation.component";
import { NgIf, NgClass } from "@angular/common";

@Component({
    selector: 'app-binary-choice-selector',
    templateUrl: './binarychoiceselector.component.html',
    styleUrl: './binarychoiceselector.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => BinaryChoiceSelectorComponent)
        }
    ],
    standalone: true,
    imports: [NgIf, NgClass, LabelComponent, ValidationComponent]
})
export class BinaryChoiceSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {

    @Input() firstOption!: string;
    @Input() secondOption!: string
    @Input() defaultValue!: string;
    @Input() label!: string;
    @Input() name!: string;
    @Input() errormessage!: string;
    @Output() selectionChange = new EventEmitter<string>();

    errors!: ValidationErrors | null | undefined;
    disabled = false;
    formControl!: NgControl | null;
    selectedOption!: string;

    constructor(
        private injector: Injector,
        @Optional() private formDirective: FormGroupDirective
    ) {}

    ngOnInit(): void {
        if (this.defaultValue !== null && this.defaultValue !== undefined) {
            this.selectOption(this.defaultValue);
        }
        this.formControl = this.ngControl;
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    onChange: any = (value: any) => {};

    onTouched: any = () => {};

    get ngControl(): NgControl | null {
        return this.injector.get(NgControl, null);
    }
    
    get submitted() {
        return this.formDirective ? this.formDirective.submitted : false;
    }

    writeValue(value: string): void {
        this.selectedOption = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    selectOption(option: string) {
        this.selectedOption = option;
        this.onChange(option);
        this.onTouched();
        this.selectionChange.emit(option);
    }
}