import { Call } from "@angular/compiler";

export interface JoiiSubHeader {
    items: JoiiSubHeaderItem[];
}

export interface JoiiSubHeaderItem {
    id: string;
    title: string;
    type: JoiiSubHeaderItemType;
}

export enum JoiiSubHeaderItemType {
    Logout = 'logout',
    Register = 'register',
    Path = 'path',
    Callback = 'callback',
    External = 'external'
}