import {Component} from '@angular/core';

@Component({
  selector: 'app-inputgroup',
  templateUrl: './inputgroup.component.html',
  standalone: true,
})

export class InputGroupComponent {
}
