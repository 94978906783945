import { Pipe, PipeTransform } from '@angular/core';
import { ClientErrorMessage } from 'src/shared/services/client/clienterror.interface';

@Pipe({
  name: 'hasErrors',
  standalone: true
})
export class HasErrorsPipe implements PipeTransform {
  transform(data: any): boolean {
    return Object.values(data).some(part => this.isClientErrorMessage(part));
  }

  private isClientErrorMessage(part: any): part is ClientErrorMessage {
    return !!part && typeof part.message !== 'undefined';
  }
}
