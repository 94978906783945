<ng-container *ngIf="recommendations$ | async as recommendations; else loading">
    <app-error *ngIf="recommendations | isClientErrorMessage; else loaded" [error]="recommendations"
        (retry)="load()"></app-error>
    <ng-template #loaded>
        <main *ngIf="!recommendations.recommendations || recommendations.recommendations.length == 0"
            class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div class="text-center">
                <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">No Product Matches</h1>
                <p class="mt-6 text-base leading-7 text-gray-600">Sorry, looks like we don't have any product
                    recommendations right now.</p>
                <div class="mt-10 flex items-center justify-center gap-x-6">
                    <a (click)="dashboard()" class="joii-btn-primary">Back to dashboard</a>
                </div>
            </div>
        </main>
        <div class="grid grid-cols-1 gap-y-8" [@list]="recommendations.recommendations.length">
            <div *ngFor="let recommendation of recommendations.recommendations" [@animate]="true"
                class="group relative flex overflow-hidden border rounded-[10px] px-10 py-6 border-neutral-300 bg-white justify-start items-center gap-x-10">
                <div class="shrink-0 flex flex-col items-center gap-6">
                    <div class="h-14 w-25 bg-gray-200 ">
                        <img [src]="recommendation.photo_url" [alt]="recommendation.name"
                            class="h-full w-full object-cover object-center">
                    </div>
                    <p class="text-base text-black font-normal leading-[21px] tracking-tight">
                        {{recommendation.price / 100 | currency:recommendation.currency:true}}</p>
                </div>
                <div class="flex flex-1 flex-col">
                    <h3 class="font-medium text-black text-base leading-[21px] tracking-tight">
                        {{recommendation.name}}
                    </h3>
                    <p class="text-base text-black font-normal leading-[21px] tracking-tight mt-4 mb-2 line-clamp-3">{{recommendation.description}}</p>
                    <div class="flex flex-1 flex-col justify-end">
                        <a target="_blank" [href]="recommendation.url"
                            class="text-cool-blue text-lg font-medium leading-normal tracking-tight underline max-w-[283.99px]">View more details</a>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
<ng-template #loading>
    <app-loader></app-loader>
</ng-template>
