import { NgClass, NgFor } from '@angular/common';
import { Component, Input, ViewChild, ElementRef } from '@angular/core';

export interface CarouselDataItem {
  image: string;
  title: string;
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  standalone: true,
  imports: [NgFor, NgClass]
})
export class CarouselComponent {
  @Input() data: CarouselDataItem[] = [];
  selectedItem: CarouselDataItem | null = null;

  @ViewChild('carousel') carousel!: ElementRef;

  ngAfterViewInit() {
    if (this.data.length > 0) {
      this.selectedItem = this.data[0];
      this.scrollToSelectedItem(0);
    }
  }

  onItemClick(item: CarouselDataItem, index: number): void {
    this.selectedItem = item;
    this.scrollToSelectedItem(index);
  }

  scrollToSelectedItem(index: number): void {
    if (this.carousel && this.carousel.nativeElement) {
      const selectedItemWidth = this.carousel.nativeElement.querySelector('.selected-item')?.offsetWidth || 0;
      const translateX = -(index * selectedItemWidth - selectedItemWidth / 2);
      this.carousel.nativeElement.style.transform = `translateX(${translateX}px)`;
    }
  }
}
