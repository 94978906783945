import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-empty-list',
  standalone: true,
  template: `
  <div class="mt-6 text-center sm:text-left" data-automationid="empty-list">
    <h3 class="text-base font-semibold leading-6 text-gray-900" data-automationid="empty-list-label">No matching results</h3>
      <div class="mt-2">
      <p class="text-sm text-gray-500" data-automationid="empty-list-remedy">Update your search term to continue.</p>
  </div>
</div>`
})
export class EmptyListComponent {

}
