import { Component, input, Input } from '@angular/core';
import { PrescribingEligibilityPillComponent } from "../prescribing-eligibility-pill/prescribing-eligibility-pill.component";

@Component({
  selector: 'app-user-details-display',
  templateUrl: './user-details-display.component.html',
  styleUrl: './user-details-display.component.scss',
  standalone: true,
  imports: [PrescribingEligibilityPillComponent]
})
export class UserDetailsDisplayComponent {

  userDetails = input<any>();
  @Input() timezone?: string;

}
