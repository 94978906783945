import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl, ValidationErrors } from "@angular/forms";
import { isEqual } from "lodash";
import { LabelComponent } from "../forms/label/label.component";
import { ValidationComponent } from "../forms/validation/validation.component";
import { NgClass, NgIf } from "@angular/common";

@Component({
    selector: 'app-yes-no',
    templateUrl: './yes-no.component.html',
    styleUrls: ['./yes-no.component.scss'], // Corrected styleUrls
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => YesNoComponent)
        }
    ],
    standalone: true,
    imports: [LabelComponent, ValidationComponent, NgIf, NgClass]
})
export class YesNoComponent implements OnInit, OnChanges, ControlValueAccessor {

    @Input() firstOption!: string;
    @Input() secondOption!: string
    @Input() defaultValue!: boolean;
    @Input() label!: string;
    @Input() name!: string;
    @Input() errormessage!: string;
    @Output() selectionChange = new EventEmitter<boolean>();

    errors!: ValidationErrors | null | undefined;
    disabled = false;
    formControl!: NgControl | null;
    selectedOption!: boolean;

    constructor(
        private injector: Injector,
        @Optional() private formDirective: FormGroupDirective
    ) {}

    ngOnInit(): void {
        if (this.defaultValue !== null && this.defaultValue !== undefined) {
            this.selectOption(this.defaultValue);
        }
        this.formControl = this.ngControl;
    }

    ngOnChanges(changes: SimpleChanges): void {
        // No need to handle value changes here since it's not an @Input property
    }

    onChange: any = (value: any) => {};

    onTouched: any = () => {};

    get ngControl(): NgControl | null {
        return this.injector.get(NgControl, null);
    }
    
    get submitted() {
        return this.formDirective ? this.formDirective.submitted : false;
    }

    writeValue(value: boolean): void {
        this.selectedOption = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    selectOption(option: boolean) {
        this.selectedOption = option;
        this.onChange(option); // Update form control value
        this.onTouched(); // Mark as touched
        this.selectionChange.emit(option);
    }
}