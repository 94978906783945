import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl, ValidationErrors } from "@angular/forms";
import { LabelComponent } from "../forms/label/label.component";
import { ValidationComponent } from "../forms/validation/validation.component";
import { NgClass, NgIf } from "@angular/common";

@Component({
    selector: 'app-weight-unit-selector',
    templateUrl: './weightunitselector.component.html',
    styleUrl: './weightunitselector.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => WeightUnitSelectorComponent)
        }
    ],
    standalone: true,
    imports: [LabelComponent, ValidationComponent, NgIf, NgClass]
})
export class WeightUnitSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {
    
    @Input() defaultUnit!: string;
    @Input() label!: string;
    @Input() name!: string;
    @Input() errormessage!: string;
    @Output() selectionChange = new EventEmitter<string>();

    errors!: ValidationErrors | null | undefined;
    disabled = false;
    formControl!: NgControl | null;
    selectedUnit!: string;

    constructor(
        private injector: Injector,
        @Optional() private formDirective: FormGroupDirective
    ) {}

    ngOnInit(): void {
        if (this.defaultUnit !== null && this.defaultUnit !== undefined) {
            this.selectUnit(this.defaultUnit);
        }
        this.formControl = this.ngControl;
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    onChange: any = (value: any) => {};

    onTouched: any = () => {};

    get ngControl(): NgControl | null {
        return this.injector.get(NgControl, null);
    }
    
    get submitted() {
        return this.formDirective ? this.formDirective.submitted : false;
    }

    writeValue(value: string): void {
        this.selectedUnit = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    selectUnit(unit: string) {
        this.selectedUnit = unit;
        this.onChange(unit); 
        this.onTouched();
        this.selectionChange.emit(unit);
    }
}