import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { prodexternal } from 'src/shared/services/client/client';
import { ClientService } from 'src/shared/services/client/client.service';

@Injectable({
    providedIn: 'root'
})

export class RecommendationsDataService {

    constructor(private client: ClientService) { }

    ListRecommendations(params: prodexternal.ListRecommendationsRequest): Observable<prodexternal.ListRecommendationsResponse> {
        return this.client.requestWithClient(client => client.prodexternal.ListRecommendations(params));
    }

}
