import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { JoiiSubHeader, JoiiSubHeaderItem, JoiiSubHeaderItemType } from './joii-sub-header.interface';
import { NgClass, NgFor } from '@angular/common';

@Component({
  selector: 'app-joii-sub-header',
  templateUrl: './joii-sub-header.component.html',
  standalone: true,
  imports: [NgFor, NgClass]
})
export class JoiiSubHeaderComponent implements OnInit {

  @Input() config: JoiiSubHeader = {
    items: []
  }
  JoiiSubHeaderItemType = JoiiSubHeaderItemType;
  currentPath: string = '';
  @Output() clickedmenuitem = new EventEmitter<JoiiSubHeaderItem>();

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router) { }

  ngOnInit(): void {
    this.currentPath = this.router.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.currentPath = event.url;
      } else if (event instanceof NavigationEnd) {
        this.currentPath = event.url;
      }
    });
  }

  menuItemClicked(menuItem: JoiiSubHeaderItem) {
    if (menuItem.type === JoiiSubHeaderItemType.Logout) {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
    } else if (menuItem.type === JoiiSubHeaderItemType.Register) {
      this.router.navigate(['/register']);
    } else if (menuItem.type === JoiiSubHeaderItemType.Callback) {
      this.clickedmenuitem.emit(menuItem);
    } else if (menuItem.type === JoiiSubHeaderItemType.Path) {
      this.router.navigate([menuItem.id]);
    }
    else if (menuItem.type === JoiiSubHeaderItemType.External) {
      window.open(menuItem.id, '_blank');
    }
  }

  menuItemChanged(selected: any) {
    let value = selected.target.value;
    let menuItem: JoiiSubHeaderItem | undefined = this.config.items.find((item: JoiiSubHeaderItem) => item.id === value);
    if (!menuItem) return;
    this.menuItemClicked(menuItem);
  }

}
